import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

//Calculation Rules
export const editorialCalculationRuleCreate = (params = '') => Http.post('/editorial-calculation-rules', params)
export const editorialCalculationRulesList = (params = '') => Http.get(`/editorial-calculation-rules${serialize(params)}`)
export const editorialCalculationRuleByID = (params = '') => Http.get(`/editorial-calculation-rules/${params}`)
export const editorialCalculationRuleUpdate = (params = '') => Http.put(`/editorial-calculation-rules/${params.id}`, params)
export const editorialCalculationRuleDestroy = (params = '') => Http.delete(`/editorial-calculation-rules/${params.id}`)

//Content Types
export const editorialContentTypeCreate = (params = '') => Http.post('/editorial-content-types', params)
export const editorialContentTypesList = (params = '') => Http.get(`/editorial-content-types${serialize(params)}`)
export const editorialContentTypeByID = (params = '') => Http.get(`/editorial-content-types/${params}`)
export const editorialContentTypeUpdate = (params = '') => Http.put(`/editorial-content-types/${params.id}`, params)
export const editorialContentTypeDestroy = (params = '') => Http.delete(`/editorial-content-types/${params.id}`)

//Journalist Categories
export const editorialJournalistCategoryCreate = (params = '') => Http.post('/editorial-journalist-categories', params)
export const editorialJournalistCategoriesList = (params = '') => Http.get(`/editorial-journalist-categories${serialize(params)}`)
export const editorialJournalistCategoryByID = (params = '') => Http.get(`/editorial-journalist-categories/${params}`)
export const editorialJournalistCategoryUpdate = (params = '') => Http.put(`/editorial-journalist-categories/${params.id}`, params)
export const editorialJournalistCategoryDestroy = (params = '') => Http.delete(`/editorial-journalist-categories/${params.id}`)
