// Importações dos serviços de API para Regras de Cálculo, Tipos de Conteúdo e Categorias de Jornalistas
import {
	editorialCalculationRuleCreate,
	editorialCalculationRulesList,
	editorialCalculationRuleByID,
	editorialCalculationRuleUpdate,
	editorialCalculationRuleDestroy,

	editorialContentTypeCreate,
	editorialContentTypesList,
	editorialContentTypeByID,
	editorialContentTypeUpdate,
	editorialContentTypeDestroy,

	editorialJournalistCategoryCreate,
	editorialJournalistCategoriesList,
	editorialJournalistCategoryByID,
	editorialJournalistCategoryUpdate,
	editorialJournalistCategoryDestroy,
} from '@/services/editorial.service'

export default {
	namespaced: true,
	state: {
		// Estado para Regras de Cálculo
		calculationRulesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		calculationRule: {},
		calculationRulesStatus: 'pending',          // Status da listagem de regras
		calculationRulesSaveStatus: 'init',         // Status do salvamento de uma regra
		calculationRulesDeleteStatus: 'init',       // Status da exclusão de uma regra

		// Estado para Tipos de Conteúdo
		contentTypesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		contentType: {},
		contentTypesStatus: 'pending',              // Status da listagem de tipos de conteúdo
		contentTypesSaveStatus: 'init',             // Status do salvamento de um tipo de conteúdo
		contentTypesDeleteStatus: 'init',           // Status da exclusão de um tipo de conteúdo

		// Estado para Categorias de Jornalistas
		journalistCategoriesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		journalistCategory: {},
		journalistCategoriesStatus: 'pending',      // Status da listagem de categorias de jornalistas
		journalistCategoriesSaveStatus: 'init',     // Status do salvamento de uma categoria de jornalista
		journalistCategoriesDeleteStatus: 'init',   // Status da exclusão de uma categoria de jornalista
	},

	getters: {
		// Getters para Regras de Cálculo
		getCalculationRulesList(state) {
			return state.calculationRulesList
		},
		getCalculationRule(state) {
			return state.calculationRule
		},
		getCalculationRulesStatus(state) {
			return state.calculationRulesStatus
		},
		getCalculationRulesSaveStatus(state) {
			return state.calculationRulesSaveStatus
		},
		getCalculationRulesDeleteStatus(state) {
			return state.calculationRulesDeleteStatus
		},

		// Getters para Tipos de Conteúdo
		getContentTypesList(state) {
			return state.contentTypesList
		},
		getContentType(state) {
			return state.contentType
		},
		getContentTypesStatus(state) {
			return state.contentTypesStatus
		},
		getContentTypesSaveStatus(state) {
			return state.contentTypesSaveStatus
		},
		getContentTypesDeleteStatus(state) {
			return state.contentTypesDeleteStatus
		},

		// Getters para Categorias de Jornalistas
		getJournalistCategoriesList(state) {
			return state.journalistCategoriesList
		},
		getJournalistCategory(state) {
			return state.journalistCategory
		},
		getJournalistCategoriesStatus(state) {
			return state.journalistCategoriesStatus
		},
		getJournalistCategoriesSaveStatus(state) {
			return state.journalistCategoriesSaveStatus
		},
		getJournalistCategoriesDeleteStatus(state) {
			return state.journalistCategoriesDeleteStatus
		},
	},

	mutations: {
		// Mutations para Regras de Cálculo
		CALCULATION_RULES_LIST(state, value) {
			state.calculationRulesList = value
		},
		SET_CALCULATION_RULE(state, value) {
			state.calculationRule = value
		},
		CALCULATION_RULES_STATUS(state, value) {
			state.calculationRulesStatus = value
		},
		CALCULATION_RULE_SAVE_STATUS(state, value) {
			state.calculationRulesSaveStatus = value
		},
		CALCULATION_RULE_DELETE_STATUS(state, value) {
			state.calculationRulesDeleteStatus = value
		},

		// Mutations para Tipos de Conteúdo
		CONTENT_TYPES_LIST(state, value) {
			state.contentTypesList = value
		},
		SET_CONTENT_TYPE(state, value) {
			state.contentType = value
		},
		CONTENT_TYPES_STATUS(state, value) {
			state.contentTypesStatus = value
		},
		CONTENT_TYPE_SAVE_STATUS(state, value) {
			state.contentTypesSaveStatus = value
		},
		CONTENT_TYPE_DELETE_STATUS(state, value) {
			state.contentTypesDeleteStatus = value
		},

		// Mutations para Categorias de Jornalistas
		JOURNALIST_CATEGORIES_LIST(state, value) {
			state.journalistCategoriesList = value
		},
		SET_JOURNALIST_CATEGORY(state, value) {
			state.journalistCategory = value
		},
		JOURNALIST_CATEGORIES_STATUS(state, value) {
			state.journalistCategoriesStatus = value
		},
		JOURNALIST_CATEGORY_SAVE_STATUS(state, value) {
			state.journalistCategoriesSaveStatus = value
		},
		JOURNALIST_CATEGORY_DELETE_STATUS(state, value) {
			state.journalistCategoriesDeleteStatus = value
		},
	},

	actions: {
		// Actions para Regras de Cálculo
		async fetchCalculationRulesList({ commit }, params) {
			commit('CALCULATION_RULES_STATUS', 'pending')
			try {
				const { data } = await editorialCalculationRulesList(params)
				commit('CALCULATION_RULES_STATUS', 'init')
				commit('CALCULATION_RULES_LIST', data)
				return data
			} catch (error) {
				commit('CALCULATION_RULES_STATUS', 'init')
				throw error
			}
		},
		async fetchCalculationRuleByID({ commit }, params) {
			commit('CALCULATION_RULES_STATUS', 'pending')
			try {
				const { data } = await editorialCalculationRuleByID(params)
				commit('CALCULATION_RULES_STATUS', 'init')
				commit('SET_CALCULATION_RULE', data.data)
				return data
			} catch (error) {
				commit('CALCULATION_RULES_STATUS', 'init')
				throw error
			}
		},
		async saveNewCalculationRule({ commit }, params) {
			commit('CALCULATION_RULE_SAVE_STATUS', 'pending')
			try {
				const { data } = await editorialCalculationRuleCreate(params)
				commit('CALCULATION_RULE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CALCULATION_RULE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateCalculationRule({ commit }, params) {
			commit('CALCULATION_RULE_SAVE_STATUS', 'pending')
			try {
				const { data } = await editorialCalculationRuleUpdate(params)
				commit('CALCULATION_RULE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CALCULATION_RULE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCalculationRuleByID({ commit }, params) {
			commit('CALCULATION_RULE_DELETE_STATUS', 'pending')
			try {
				const { data } = await editorialCalculationRuleDestroy(params)
				commit('CALCULATION_RULE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CALCULATION_RULE_DELETE_STATUS', 'error')
				throw error
			}
		},

		// Actions para Tipos de Conteúdo
		async fetchContentTypesList({ commit }, params) {
			commit('CONTENT_TYPES_STATUS', 'pending')
			try {
				const { data } = await editorialContentTypesList(params)
				commit('CONTENT_TYPES_STATUS', 'init')
				commit('CONTENT_TYPES_LIST', data)
				return data
			} catch (error) {
				commit('CONTENT_TYPES_STATUS', 'init')
				throw error
			}
		},
		async fetchContentTypeByID({ commit }, params) {
			commit('CONTENT_TYPES_STATUS', 'pending')
			try {
				const { data } = await editorialContentTypeByID(params)
				commit('CONTENT_TYPES_STATUS', 'init')
				commit('SET_CONTENT_TYPE', data.data)
				return data
			} catch (error) {
				commit('CONTENT_TYPES_STATUS', 'init')
				throw error
			}
		},
		async saveNewContentType({ commit }, params) {
			commit('CONTENT_TYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await editorialContentTypeCreate(params)
				commit('CONTENT_TYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CONTENT_TYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateContentType({ commit }, params) {
			commit('CONTENT_TYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await editorialContentTypeUpdate(params)
				commit('CONTENT_TYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CONTENT_TYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteContentTypeByID({ commit }, params) {
			commit('CONTENT_TYPE_DELETE_STATUS', 'pending')
			try {
				const { data } = await editorialContentTypeDestroy(params)
				commit('CONTENT_TYPE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CONTENT_TYPE_DELETE_STATUS', 'error')
				throw error
			}
		},

		// Actions para Categorias de Jornalistas
		async fetchJournalistCategoriesList({ commit }, params) {
			commit('JOURNALIST_CATEGORIES_STATUS', 'pending')
			try {
				const { data } = await editorialJournalistCategoriesList(params)
				commit('JOURNALIST_CATEGORIES_STATUS', 'init')
				commit('JOURNALIST_CATEGORIES_LIST', data)
				return data
			} catch (error) {
				commit('JOURNALIST_CATEGORIES_STATUS', 'init')
				throw error
			}
		},
		async fetchJournalistCategoryByID({ commit }, params) {
			commit('JOURNALIST_CATEGORIES_STATUS', 'pending')
			try {
				const { data } = await editorialJournalistCategoryByID(params)
				commit('JOURNALIST_CATEGORIES_STATUS', 'init')
				commit('SET_JOURNALIST_CATEGORY', data.data)
				return data
			} catch (error) {
				commit('JOURNALIST_CATEGORIES_STATUS', 'init')
				throw error
			}
		},
		async saveNewJournalistCategory({ commit }, params) {
			commit('JOURNALIST_CATEGORY_SAVE_STATUS', 'pending')
			try {
				const { data } = await editorialJournalistCategoryCreate(params)
				commit('JOURNALIST_CATEGORY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('JOURNALIST_CATEGORY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateJournalistCategory({ commit }, params) {
			commit('JOURNALIST_CATEGORY_SAVE_STATUS', 'pending')
			try {
				const { data } = await editorialJournalistCategoryUpdate(params)
				commit('JOURNALIST_CATEGORY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('JOURNALIST_CATEGORY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteJournalistCategoryByID({ commit }, params) {
			commit('JOURNALIST_CATEGORY_DELETE_STATUS', 'pending')
			try {
				const { data } = await editorialJournalistCategoryDestroy(params)
				commit('JOURNALIST_CATEGORY_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('JOURNALIST_CATEGORY_DELETE_STATUS', 'error')
				throw error
			}
		},
	},
}
