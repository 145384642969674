const mundoIndie = [
	{
		path: '/agency/mundo-indie/workflow-status/list',
		name: 'agency-workflow-status-list',
		component: () => import('@/views/agency/workflow-status/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/mundo-indie/game-status/list',
		name: 'agency-game-status-list',
		component: () => import('@/views/agency/game-status/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/mundo-indie/goals/list',
		name: 'agency-goals-list',
		component: () => import('@/views/agency/goals/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/mundo-indie/projects/list',
		name: 'agency-projects-list',
		component: () => import('@/views/agency/projects/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default mundoIndie
